
import {MSIcon} from './MSIcon';

export class MSDropDown {

    constructor(iConfig) {
        this.m_cfig = iConfig;
        this.m_uid = ms_uid();
    }

    create(iConfig) {
        const $ul = $("<ul/>").addClass("dropdown-menu ms");
        if (iConfig.class) {
            $ul.addClass(iConfig.class);
        }
        if (iConfig.id) {
            $ul.attr('id', iConfig.id);
        }
        $ul.attr('data-bs-popper', "static");
        iConfig.items.forEach((item) => {
            this.create_item(item).appendTo($ul);
        });
        return $ul;
    };

    create_item(iItem) {
        const $li = $("<li/>");

        // console.log((iItem))
        // console.log(typeof (iItem))

        if (typeof (iItem) == 'string') {
            if (iItem == '') {
                $("<hr/>").addClass("dropdown-divider").appendTo($li);
            } else {
                $("<h6/>").addClass("dropdown-header").text(iItem).appendTo($li);
            }
            return $li;
        }else if(typeof (iItem) == "object" && (iItem instanceof jQuery || iItem.nodeType)) {
            $li.html(iItem)
            return $li;
        }

        if (iItem.id) {
            $li.attr('item_id', iItem.id);
        }
        const $a = $("<a/>").addClass("dropdown-item d-flex").appendTo($li);
        if (iItem.icon) {
            if(iItem.icon.indexOf("https")>=0){
                var $icon=$("<img/>").addClass("icon ms_icon icon_svg my-auto").attr({"src":iItem.icon}).appendTo($a)
            }else{
                MSIcon.$(iItem.icon).appendTo($a);
            }
            
        }
        if (iItem.href) {
            $a.attr("href", iItem.href);
        }

        if(iItem.action) {
            $a.on("click",() => {
                iItem.action();
            })
        }

        var $span=$("<span/>").addClass("label ms-2 my-auto").text(htmlent(iItem.text)).appendTo($a);

        // $a.append(htmlent(iItem.text));

        if (iItem.items) {
            $li.addClass("has-items");
            const $ul = $("<ul/>").addClass("dropdown-menu dropdown-submenu").appendTo($li);
            iItem.items.forEach((item) => {
                this.create_item(item).appendTo($ul);
            });
        }
        return $li;
    }
}

MSDropDown.create = (iConfig) => {
    const obj = new MSDropDown(iConfig);
    return obj.create(iConfig);
};

